import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  People,
  ShoppingCart,
  PersonAdd,
  Agriculture,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { styled } from "@mui/material/styles";
import { DynamoDBClient, ScanCommand } from "@aws-sdk/client-dynamodb";
import { unmarshall } from "@aws-sdk/util-dynamodb";

import UserTable from "../users/UserTable";
import OrderTable from "../orders/OrderTable";
import PendingTestersTable from "../tester/PendingTestersTable";
import SeedRequestsTable from "../pending/SeedRequestsTable";
import { useNavigate } from "react-router-dom";

import getAwsConfig from "../../awsconfig";
const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "0.3s",
  pointerEvents: "none",
}));

const StyledCardWithHover = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "0.3s",
  cursor: 'pointer',
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[4],
  },
}));

function Dashboard() {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [pendingTesters, setPendingTesters] = useState(0);
  const [seedRequests, setSeedRequests] = useState(0);
  const [totalMade, setTotalMade] = useState(0);
  const [totalMadeProfit, setTotalMadeProfit] = useState(0);
  const [totalLasariaCost, setTotalLasariaCost] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [userGrowth, setUserGrowth] = useState({
    lastMonth: 0,
    last3Months: 0,
    last6Months: 0,
    lastYear: 0,
    monthlyData: [],
  });

  const [revenueGrowth, setRevenueGrowth] = useState({
    lastMonth: 0,
    last3Months: 0,
    last6Months: 0,
    lastYear: 0,
    monthlyData: [],
  });

  const [orderGrowth, setOrderGrowth] = useState({
    lastMonth: 0,
    last3Months: 0,
    last6Months: 0,
    lastYear: 0,
    monthlyData: [],
  });
  // const [client, setClient] = useState(null);

  // useEffect(() => {
  //   async function fetchAwsConfig() {
  //     const awsconfig = await getAwsConfig();
  //     const client = new DynamoDBClient(awsconfig);
  //     setClient(client);
  //   }

  //   fetchAwsConfig();
  // }, []);
  const calculateGrowthMetrics = async () => {
    try {
      const now = new Date();
      const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
      const threeMonthsAgo = new Date(now.setMonth(now.getMonth() - 2));
      const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 3));
      const oneYearAgo = new Date(now.setFullYear(now.getFullYear() - 1));

      // Fetch users with creation dates
      const usersCommand = new ScanCommand({
        TableName: "users",
        ProjectionExpression: "#cd",
        ExpressionAttributeNames: {
          "#cd": "createDate",
        },
      });

      const usersResponse = await client.send(usersCommand);
      const users = usersResponse.Items.map((item) => unmarshall(item));

      // Fetch orders with dates and totals
      const ordersCommand = new ScanCommand({
        TableName: "orders",
        ProjectionExpression: "#cd, #t",
        ExpressionAttributeNames: {
          "#cd": "createDate",
          "#t": "total",
        },
      });

      const ordersResponse = await client.send(ordersCommand);
      const orders = ordersResponse.Items.map((item) => unmarshall(item));

      // Process monthly data
      const monthlyData = Array.from({ length: 12 }, (_, i) => {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        const monthYear = date.toLocaleString("default", {
          month: "short",
          year: "2-digit",
        });

        const usersInMonth = users.filter((user) => {
          const userDate = new Date(user.createDate);
          return (
            userDate.getMonth() === date.getMonth() &&
            userDate.getFullYear() === date.getFullYear()
          );
        }).length;

        const ordersInMonth = orders.filter((order) => {
          const orderDate = new Date(order.createDate);
          return (
            orderDate.getMonth() === date.getMonth() &&
            orderDate.getFullYear() === date.getFullYear()
          );
        });

        const revenueInMonth = ordersInMonth.reduce(
          (sum, order) => sum + (parseFloat(order.total) || 0),
          0
        );

        return {
          month: monthYear,
          users: usersInMonth,
          orders: ordersInMonth.length,
          revenue: revenueInMonth,
        };
      }).reverse();

      // Calculate growth metrics
      const calculateGrowth = (data, dateThreshold) =>
        data.filter((item) => new Date(item.createDate) >= dateThreshold)
          .length;

      setUserGrowth({
        lastMonth: calculateGrowth(users, oneMonthAgo),
        last3Months: calculateGrowth(users, threeMonthsAgo),
        last6Months: calculateGrowth(users, sixMonthsAgo),
        lastYear: calculateGrowth(users, oneYearAgo),
        monthlyData: monthlyData.map(({ month, users }) => ({
          month,
          value: users,
        })),
      });

      setOrderGrowth({
        lastMonth: calculateGrowth(orders, oneMonthAgo),
        last3Months: calculateGrowth(orders, threeMonthsAgo),
        last6Months: calculateGrowth(orders, sixMonthsAgo),
        lastYear: calculateGrowth(orders, oneYearAgo),
        monthlyData: monthlyData.map(({ month, orders }) => ({
          month,
          value: orders,
        })),
      });

      setRevenueGrowth({
        lastMonth: monthlyData[monthlyData.length - 1].revenue,
        last3Months: monthlyData
          .slice(-3)
          .reduce((sum, month) => sum + month.revenue, 0),
        last6Months: monthlyData
          .slice(-6)
          .reduce((sum, month) => sum + month.revenue, 0),
        lastYear: monthlyData.reduce((sum, month) => sum + month.revenue, 0),
        monthlyData: monthlyData.map(({ month, revenue }) => ({
          month,
          value: revenue,
        })),
      });
    } catch (error) {
      console.error("Error calculating growth metrics:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (!client) return;

      try {
        const usersCommand = new ScanCommand({
          TableName: "users",
          Select: "COUNT",
        });
        const usersResult = await client.send(usersCommand);
        setTotalUsers(usersResult.Count);

        const ordersCommand = new ScanCommand({
          TableName: "orders",
          Select: "COUNT",
        });
        const ordersResult = await client.send(ordersCommand);
        setTotalOrders(ordersResult.Count);

        const pendingTestersCommand = new ScanCommand({
          TableName: "users",
          FilterExpression: "isTester = :pending",
          ExpressionAttributeValues: {
            ":pending": { S: "pending" },
          },
          Select: "COUNT",
        });
        const pendingTestersResult = await client.send(pendingTestersCommand);
        setPendingTesters(pendingTestersResult.Count);

        const seedRequestsCommand = new ScanCommand({
          TableName: "users",
          FilterExpression: "requestedSeeds = :true",
          ExpressionAttributeValues: {
            ":true": { BOOL: true },
          },
          Select: "COUNT",
        });
        const seedRequestsResult = await client.send(seedRequestsCommand);
        setSeedRequests(seedRequestsResult.Count);

        var totalSum = 0;
        var lastEvaluatedKey = null;
        const params = {
          TableName: "orders",
          ProjectionExpression: "#t",
          ExpressionAttributeNames: {
            "#t": "total",
          },
        };

        do {
          if (lastEvaluatedKey) {
            params.ExclusiveStartKey = lastEvaluatedKey;
          }

          const command = new ScanCommand(params);
          const response = await client.send(command);
          console.log(response);

          const items = response.Items.map((item) => unmarshall(item));
          totalSum += items.reduce((sum, item) => sum + item.total, 0);

          lastEvaluatedKey = response.LastEvaluatedKey;
        } while (lastEvaluatedKey);
        setTotalMade(parseFloat(totalSum));
        console.log("total made: " + totalMade);

        var totalSumProfit = 0;
        var lastEvaluatedKeyProfit = null;
        const paramsProfit = {
          TableName: "Profits",
          ProjectionExpression: "#p",
          ExpressionAttributeNames: {
            "#p": "profit",
          },
        };

        do {
          if (lastEvaluatedKeyProfit) {
            paramsProfit.ExclusiveStartKey = lastEvaluatedKeyProfit;
          }

          const commandProfit = new ScanCommand(paramsProfit);
          const responseProfit = await client.send(commandProfit);
          console.log(responseProfit);

          const itemsProfit = responseProfit.Items.map((item) =>
            unmarshall(item)
          );
          const batchSum = itemsProfit.reduce((sum, item) => {
            const parsedProfit = parseFloat(parseFloat(item.profit).toFixed(2));
            return sum + parsedProfit;
          }, 0);

          totalSumProfit = parseFloat((totalSumProfit + batchSum).toFixed(2));

          console.log("Batch Profit Sum: " + batchSum.toFixed(2));
          console.log("Running Total Profit: " + totalSumProfit.toFixed(2));

          lastEvaluatedKeyProfit = responseProfit.LastEvaluatedKey;
        } while (lastEvaluatedKeyProfit);
        setTotalMadeProfit(parseFloat(totalSumProfit));
        setTotalLasariaCost(parseFloat(totalSumProfit * 0.04).toFixed(2));
        console.log("total made: " + totalMadeProfit);

        await calculateGrowthMetrics();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [client]);

  return (
    <Container maxWidth="100%" disableGutters>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          gutterBottom
          align="center"
        >
          Dashboard
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {/* First Row: Total Made, Total Profit, Total Lasaria Cost */}
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <StyledCard>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "green" }}>
                      <AttachMoneyIcon />
                    </Avatar>
                  }
                  title="Total Made"
                />
                <CardContent>
                  <Typography variant="h4">${totalMade}</Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <StyledCard>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "limeGreen" }}>
                      <AttachMoneyIcon />
                    </Avatar>
                  }
                  title="Total Profit"
                />
                <CardContent>
                  <Typography variant="h4">${totalMadeProfit}</Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <StyledCard>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "magenta" }}>
                      <AttachMoneyIcon />
                    </Avatar>
                  }
                  title="Total Lasaria Cost"
                />
                <CardContent>
                  <Typography variant="h4">${totalLasariaCost}</Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>

          {/* Combined Second Row: Total Users, Total Orders, Pending Testers, Seed Requests */}
          <Grid container item xs={12} spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={3} onClick={() => navigate("/users")}>
              <StyledCardWithHover>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "primary.main" }}>
                      <People />
                    </Avatar>
                  }
                  title="Total Users"
                />
                <CardContent>
                  <Typography variant="h4">{totalUsers}</Typography>
                </CardContent>
              </StyledCardWithHover>
            </Grid>
            <Grid item xs={12} sm={6} md={3} onClick={() => navigate("/orders")}>
              <StyledCardWithHover>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "#40E0D0" }}>
                      <ShoppingCart />
                    </Avatar>
                  }
                  title="Total Orders"
                />
                <CardContent>
                  <Typography variant="h4">{totalOrders}</Typography>
                </CardContent>
              </StyledCardWithHover>
            </Grid>
            <Grid item xs={12} sm={6} md={3} onClick={() => navigate("/users/pending")}>
              <StyledCardWithHover>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "#b59326" }}>
                      <PersonAdd />
                    </Avatar>
                  }
                  title="Pending Testers"
                />
                <CardContent>
                  <Typography variant="h4">{pendingTesters}</Typography>
                </CardContent>
              </StyledCardWithHover>
            </Grid>
            <Grid item xs={12} sm={6} md={3} onClick={() => navigate("/users/seed-requests")}>
              <StyledCardWithHover>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "warning.main" }}>
                      <Agriculture />
                    </Avatar>
                  }
                  title="Seed Requests"
                />
                <CardContent>
                  <Typography variant="h4">{seedRequests}</Typography>
                </CardContent>
              </StyledCardWithHover>
            </Grid>
          </Grid>
        </Grid>

        <Typography
          variant="h5"
          gutterBottom
          sx={{
            mt: 6,
            mb: 3,
            textAlign: "center",
            color: "primary.main",
            fontWeight: "bold",
          }}
        >
          Growth Analytics
        </Typography>

        <Grid container spacing={3}>
          {/* Users Growth Chart */}
          <Grid item xs={12} lg={4}>
            <Card sx={{ height: "100%" }}>
              <CardHeader title="User Growth" />
              <CardContent>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={userGrowth.monthlyData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="value"
                        name="New Users"
                        stroke="#00ff00"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Month
                    </Typography>
                    <Typography variant="h6">{userGrowth.lastMonth}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last 3 Months
                    </Typography>
                    <Typography variant="h6">
                      {userGrowth.last3Months}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last 6 Months
                    </Typography>
                    <Typography variant="h6">
                      {userGrowth.last6Months}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Year
                    </Typography>
                    <Typography variant="h6">{userGrowth.lastYear}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Revenue Growth Chart */}
          <Grid item xs={12} lg={4}>
            <Card sx={{ height: "100%" }}>
              <CardHeader title="Revenue Growth" />
              <CardContent>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={revenueGrowth.monthlyData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="value" name="Revenue" fill="#00ff00" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Month
                    </Typography>
                    <Typography variant="h6">
                      ${revenueGrowth.lastMonth}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last 3 Months
                    </Typography>
                    <Typography variant="h6">
                      ${revenueGrowth.last3Months}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last 6 Months
                    </Typography>
                    <Typography variant="h6">
                      ${revenueGrowth.last6Months}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Year
                    </Typography>
                    <Typography variant="h6">
                      ${revenueGrowth.lastYear}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Orders Growth Chart */}
          <Grid item xs={12} lg={4}>
            <Card sx={{ height: "100%" }}>
              <CardHeader title="Order Growth" />
              <CardContent>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={orderGrowth.monthlyData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="value"
                        name="Orders"
                        stroke="#00ff00"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Month
                    </Typography>
                    <Typography variant="h6">
                      {orderGrowth.lastMonth}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last 3 Months
                    </Typography>
                    <Typography variant="h6">
                      {orderGrowth.last3Months}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last 6 Months
                    </Typography>
                    <Typography variant="h6">
                      {orderGrowth.last6Months}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Year
                    </Typography>
                    <Typography variant="h6">{orderGrowth.lastYear}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Box mt={4}>
          <UserTable limit={isMobile ? 3 : 5} />
        </Box>

        <Box mt={4}>
          <OrderTable limit={isMobile ? 3 : 5} />
        </Box>

        <Box mt={4}>
          <PendingTestersTable limit={isMobile ? 3 : 5} />
        </Box>

        <Box mt={4}>
          <SeedRequestsTable limit={isMobile ? 3 : 5} />
        </Box> */}
      </Box>
    </Container>
  );
}

export default Dashboard;
