import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Typography, Paper, Avatar, List, ListItem, ListItemText } from '@mui/material';
import { DynamoDBClient, ScanCommand, GetItemCommand } from '@aws-sdk/client-dynamodb';
import { marshall, unmarshall } from '@aws-sdk/util-dynamodb';

import getAwsConfig from "../../awsconfig";
const awsconfig = await getAwsConfig();
const client = new DynamoDBClient(awsconfig);


const fetchUser = async (userId) => {
  try {
    const params = {
      TableName: 'users',
      Key: {
        id: { S: userId },
        email: { S: userId },
      },
    };
    const command = new GetItemCommand(params);
    const data = await client.send(command);
    return unmarshall(data.Item);
  } catch (err) {
    console.error('Error fetching user:', err);
    throw err;
  }
};

const UserDetail = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await fetchUser(userId);
        console.log(user);
        setUser(user);
      } catch (err) {
        console.error('Error:', err);
      }
    };

    getUser();
  }, [userId]);

  return user ? (
    <Box>
      <Typography variant="h4" gutterBottom>
        User Detail
      </Typography>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar src={user.profilePic} alt={user.firstName} sx={{ width: 64, height: 64, mr: 2 }} />
          <Typography variant="h5">{`${user.firstName} ${user.lastName}`}</Typography>
        </Box>
        <Typography variant="body1">Email: {user.email}</Typography>
        <Typography variant="body1">Instagram: {user.instagram}</Typography>
        <Typography variant="body1">Local Pickup: {user.localPickup}</Typography>
        <Typography variant="body1">Billing Address: {user.billingAddress} {user.billingAddress2} {user.billingCity} {user.billingState} {user.billingZip}</Typography>
        <Typography variant="body1">Shipping Address: {user.shippingStreetAddress} {user.shippingStreetAddress2} {user.shippingCity} {user.shippingState} {user.shippingZip}</Typography>
        <Typography variant="body1">Rewards Points: {user.rewardsPoints}</Typography>
        <Typography variant="body1">Tester Count: {user.testerCount}</Typography>
        <Typography variant="body1">Tester Date: {user.testerDate}</Typography>
        <Typography variant="body1">Requested Seeds: {user.requestedSeeds}</Typography>
        <Typography variant="body1">T-Shirt Size: {user.tshirtSize}</Typography>
        <Typography variant="h6" mt={2}>Orders {user.orderCount}</Typography>
        <List>
          {user.orders && user.orders.map((order) => (
            <ListItem key={order.id} button component={Link} to={`/orders/${order.id}`}>
              <ListItemText
                primary={`Order #${order.id}`}
                secondary={`Date: ${new Date(order.createDate).toLocaleString()}`}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  ) : (
    <Typography variant="h6">Loading user details...</Typography>
  );
};

export default UserDetail;
