import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  List,
  useMediaQuery,
  useTheme,
  Collapse,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Dashboard,
  People,
  ListAlt,
  Inventory,
  Stars,
  Settings,
  Logout,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Link, useNavigate } from "react-router-dom";

const MyAppBar = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openMenus, setOpenMenus] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const handleDesktopMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenus({});
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNestedMenuToggle = (menuName) => {
    setOpenMenus((prev) => ({ ...prev, [menuName]: !prev[menuName] }));
  };

  const menuItems = [
    { icon: <Dashboard />, text: "Dashboard", link: "/" },
    {
      icon: <People />,
      text: "Users",
      subitems: [
        { text: "All Users", link: "/users" },
        { text: "Pending Testers", link: "/users/pending" },
        { text: "Seed Requests", link: "/users/seed-requests" },
      ],
    },
    {
      icon: <ListAlt />,
      text: "Orders",
      subitems: [
        { text: "All Orders", link: "/orders" },
        { text: "Fulfilled Orders", link: "/orders/fulfilled" },
        { text: "Unfulfilled Orders", link: "/orders/unfulfilled" },
      ],
    },
    {
      icon: <Inventory />,
      text: "Listings",
      subitems: [
        { text: "All Listings", link: "/listings" },
        { text: "Active Listings", link: "/listings/active" },
        { text: "Inactive Listings", link: "/listings/inactive" },
        { text: "Create Seed Listing", link: "/create-listing" },
      ],
    },
    { icon: <Stars />, text: "Rewards", link: "/rewards" },
    { icon: <Settings />, text: "Settings", link: "/settings" },
  ];

  const renderMenuItems = (items, nested = false) =>
    items.map((item, index) => (
      <React.Fragment key={index}>
        {item.subitems ? (
          <>
            <MenuItem onClick={() => handleNestedMenuToggle(item.text)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
              {openMenus[item.text] ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={openMenus[item.text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(item.subitems, true)}
              </List>
            </Collapse>
          </>
        ) : (
          <MenuItem
            component={Link}
            to={item.link}
            onClick={() => {
              handleMenuClose();
              if (isMobile) setMobileOpen(false);
            }}
            style={{ paddingLeft: nested ? 32 : 16 }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        )}
      </React.Fragment>
    ));

  const desktopMenu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      PaperProps={{
        style: {
          maxHeight: "80vh",
          width: "250px",
        },
      }}
    >
      {renderMenuItems(menuItems)}
    </Menu>
  );

  const mobileDrawer = (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        "& .MuiDrawer-paper": { width: 250 },
      }}
    >
      <List>{renderMenuItems(menuItems)}</List>
    </Drawer>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {isMobile ? (
            <IconButton
              color="inherit"
              aria-label="open navigation"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Button
              color="inherit"
              onClick={handleDesktopMenuOpen}
              sx={{ mr: 2 }}
              endIcon={<ExpandMore />}
            >
              Menu
            </Button>
          )}

          {/* Conditionally centered title for non-mobile */}
          <Typography
            variant="h6"
            noWrap
            onClick={() => navigate("/")}
            sx={
              !isMobile
                ? {
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    cursor: "pointer",
                  }
                : { flexGrow: 1 } // On mobile, let it naturally fill space
            }
          >
            HG Admin Console
          </Typography>

          {/* Right-side user info */}
          {!isMobile && (
            <>
              <Typography variant="body2" sx={{ mr: 2, marginLeft: "auto" }}>
                {user?.signInDetails?.loginId}
              </Typography>
              <Button color="inherit" onClick={signOut}>
                Sign out
              </Button>
            </>
          )}
          <Avatar
            alt={user?.signInDetails?.loginId?.toUpperCase() || ""}
            src="/path/to/avatar.jpg"
            sx={{ ml: 2 }}
          />
        </Toolbar>
        {isMobile ? mobileDrawer : desktopMenu}
      </AppBar>
    </>
  );
};

export default MyAppBar;
