import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import {
  CssBaseline,
  Box,
  Toolbar,
  useMediaQuery,
  Grid,
  Button,
} from "@mui/material";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import MyAppBar from "./components/AppBar";
import Dashboard from "./views/dashboard/Dashboard";
import UserTable from "./views/users/UserTable";
import UserOrderHistoryContent from "./views/users/UserOrderHistoryContent";
import OrderTable from "./views/orders/OrderTable";
import OrderDetail from "./views/orders/OrderDetail";
import RewardTable from "./views/rewards/RewardTable";
import ListingPage from "./views/listing/ListingPage";
import ActiveListingsPage from "./views/listing/ActiveListingsPage";
import InactiveListingsPage from "./views/listing/InactiveListingsPage";
import EditListingPage from "./views/listing/EditListingPage";
import ListingForm from "./views/listing/ListingForm";
import PendingTestersTable from "./views/tester/PendingTestersTable";
import SeedRequestsTable from "./views/pending/SeedRequestsTable";
import UserDetail from "./views/users/UserDetail";
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "6b90agk2p4g04g5m2tpc27rejv",
      userPoolId: "us-east-2_xzDnCbCEO",
      loginWith: {
        email: "true",
      },
    },
  },
});

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#00ff00",
      light: "#33ff33",
      dark: "#00cc00",
      contrastText: "#000000",
    },
    secondary: {
      main: "#1E1E1E",
      light: "#2d2d2d",
      dark: "#151515",
    },
    background: {
      default: "#121212",
      paper: "#1E1E1E",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b3b3b3",
    },
    success: {
      main: "#00ff00",
      light: "#33ff33",
      dark: "#00cc00",
    },
    warning: {
      main: "#ffb74d",
    },
    error: {
      main: "#ff1744",
    },
    action: {
      hover: "rgba(0, 255, 0, 0.08)",
      selected: "rgba(0, 255, 0, 0.16)",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#1E1E1E",
          borderRadius: 8,
          border: "1px solid #333",
          transition: "all 0.3s ease",
          "&:hover": {
            borderColor: "#00ff00",
            boxShadow: "0 0 10px rgba(0, 255, 0, 0.2)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1E1E1E",
          backgroundImage: "none",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1E1E1E",
          backgroundImage: "none",
          borderBottom: "1px solid #333",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#1E1E1E",
          borderRight: "1px solid #333",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "#333",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#ff1744",
          "&.Mui-checked": {
            color: "#00ff00",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "0 0 10px rgba(0, 255, 0, 0.2)",
          "&:hover": {
            boxShadow: "0 0 15px rgba(0, 255, 0, 0.3)",
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    h4: {
      color: "#00ff00",
      fontWeight: 600,
    },
    h5: {
      color: "#00ff00",
      fontWeight: 600,
    },
    h6: {
      color: "#ffffff",
      fontWeight: 600,
    },
  },
});

const AuthBackground = styled(Box)(({ theme }) => ({
  backgroundImage: 'url("./Admin Portal.png")',
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, isMobile }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    width: "100%",
    overflowX: "auto",
    boxSizing: "border-box",
    minWidth: 0,
  })
);

// New modular home screen component displaying buttons
const AdminHome = () => {
  const navigate = useNavigate();

  const pages = [
    { path: "/users", label: "Users" },
    { path: "/orders", label: "Orders" },
    { path: "/rewards", label: "Rewards" },
    { path: "/listings", label: "Listings" },
    { path: "/users/pending", label: "Pending Testers" },
    { path: "/users/seed-requests", label: "Seed Requests" },
  ];

  return (
    <div>
      {/* Display Dashboard at the top */}
      <Dashboard />

      {/* Navigation Buttons */}
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {pages.map((page) => (
          <Grid item xs={12} sm={6} md={4} key={page.path}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => navigate(page.path)}
            >
              {page.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

function App() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthBackground>
        <Authenticator variation="modal">
          {({ signOut }) => (
            <Router>
              <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <MyAppBar handleDrawerToggle={handleDrawerToggle} />
                <Main>
                  <Toolbar />
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    {/* Use AdminHome as the root route */}
                    <Route path="/" element={<AdminHome />} />

                    <Route path="/users" element={<UserTable />} />
                    <Route
                      path="/orders/user/:userId"
                      element={<UserOrderHistoryContent />}
                    />
                    <Route
                      path="/users/pending"
                      element={<PendingTestersTable />}
                    />
                    <Route
                      path="/users/seed-requests"
                      element={<SeedRequestsTable />}
                    />
                    <Route path="/users/:userId" element={<UserDetail />} />
                    <Route path="/orders" element={<OrderTable />} />
                    <Route
                      path="/orders/fulfilled"
                      element={<OrderTable fulfilled={true} />}
                    />
                    <Route
                      path="/orders/unfulfilled"
                      element={<OrderTable fulfilled={false} />}
                    />
                    <Route path="/orders/:orderId" element={<OrderDetail />} />
                    <Route path="/rewards" element={<RewardTable />} />
                    <Route path="/listings" element={<ListingPage />} />
                    <Route
                      path="/listings/active"
                      element={<ActiveListingsPage />}
                    />
                    <Route
                      path="/listings/inactive"
                      element={<InactiveListingsPage />}
                    />
                    <Route path="/create-listing" element={<ListingForm />} />
                    <Route
                      path="/listings/edit/:id/:createdDate"
                      element={<EditListingPage />}
                    />
                  </Routes>
                </Main>
              </Box>
            </Router>
          )}
        </Authenticator>
      </AuthBackground>
    </ThemeProvider>
  );
}

export default App;
