import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Switch,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Tooltip,
  Zoom,
  useMediaQuery,
  useTheme,
  Hidden,
  Modal,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
} from "@mui/material";
import {
  QuestionMark,
  ViewList,
  Add,
  Check,
  Instagram,
  Search,
  Refresh,
  Person,
  ShoppingCart,
  Stars,
  LocalShipping,
  Settings,
  ArrowUpward,
  ArrowDownward,
  Badge,
  CalendarToday,
  Update,
  Numbers,
} from "@mui/icons-material";
import {
  DynamoDBClient,
  ScanCommand,
  UpdateItemCommand,
} from "@aws-sdk/client-dynamodb";
import getAwsConfig from "../../awsconfig";
import { styled, keyframes } from "@mui/system";
import { Checkmark } from "react-checkmark";
import { motion, AnimatePresence } from "framer-motion";

const PulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const AnimatedTableBody = styled(TableBody)({
  "& > tr": {
    opacity: 0,
  },
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  overflowX: "auto",
  "& .MuiTable-root": {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    "& .MuiTable-root": {
      minWidth: 600,
    },
  },
}));

const ResponsiveTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down("xl")]: {
    padding: theme.spacing(1.5),
  },
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0.5),
  },
}));

const ResponsiveText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  [theme.breakpoints.down("xl")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.8rem",
  },
}));

const StyledSearchBar = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  marginBottom: theme.spacing(3),
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[8],
  },
}));

const SettingsModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  minWidth: 300,
  maxWidth: 500,
  maxHeight: "80vh",
  overflow: "auto",
}));

const UserIcon = styled(Person)(({ theme }) => ({
  fontSize: 60,
  color: theme.palette.primary.main,
  animation: `${PulseAnimation} 2s ease-in-out infinite`,
}));

const UserTable = () => {
  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editedUsers, setEditedUsers] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rewardPoints, setRewardPoints] = useState(0);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [client, setClient] = useState(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "createDate",
    direction: "desc",
  });

  const [visibleColumns, setVisibleColumns] = useState({
    firstName: true,
    lastName: true,
    email: true,
    instagram: true,
    createDate: true,
    rewardsPoints: true,
    orderCount: true,
    testerCount: true,
    tester: true,
    requestedSeeds: true,
    localPickup: true,
    tshirtSize: true,
    profilePic: false,
  });

  useEffect(() => {
    async function fetchAwsConfig() {
      const awsconfig = await getAwsConfig();
      const client = new DynamoDBClient(awsconfig);
      setClient(client);
    }

    fetchAwsConfig();
  }, []);

  const fetchUserData = async () => {
    if (!client) return;

    try {
      const userData = await fetchUsers();
      const parsedData = parseUsers(userData);
      setUsers(parsedData);
      setFilteredUsers(parsedData);
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [client]);

  useEffect(() => {
    const filtered = users.filter((user) => {
      const lowercasedQuery = searchQuery.toLowerCase();
      return (
        user.firstName.toLowerCase().includes(lowercasedQuery) ||
        user.lastName.toLowerCase().includes(lowercasedQuery) ||
        user.email.toLowerCase().includes(lowercasedQuery)
      );
    });
    setFilteredUsers(filtered);
    setSelectedUsers([]);
    setSelectAll(false);
  }, [searchQuery, users]);

  const columns = [
    { id: "firstName", label: "First Name", icon: <Badge /> },
    { id: "lastName", label: "Last Name", icon: <Badge /> },
    { id: "email", label: "Email", icon: <Person /> },
    { id: "instagram", label: "Instagram", icon: <Instagram /> },
    { id: "createDate", label: "Created Date", icon: <CalendarToday /> },
    { id: "rewardsPoints", label: "Reward Points", icon: <Stars /> },
    { id: "orderCount", label: "Orders", icon: <ShoppingCart /> },
    { id: "testerCount", label: "Tester Seeds", icon: <Numbers /> },
    { id: "tester", label: "Tester Status", icon: <Badge /> },
    {
      id: "requestedSeeds",
      label: "Seed Request",
      icon: <QuestionMark />,
    },
    { id: "localPickup", label: "Local Pickup", icon: <LocalShipping /> },
    { id: "tshirtSize", label: "T-Shirt Size", icon: <Person /> },
  ];

  const fetchUsers = async () => {
    if (!client) return;

    try {
      const input = {
        TableName: "users",
      };
      const command = new ScanCommand(input);
      const data = await client.send(command);
      return data.Items;
    } catch (err) {
      console.error("Error scanning items:", err);
      throw err;
    }
  };

  const updateUserField = async (email, field, value) => {
    try {
      const params = {
        TableName: "users",
        Key: {
          email: { S: email },
          id: { S: email },
        },
        UpdateExpression: `SET #field = :value`,
        ExpressionAttributeNames: {
          "#field": field,
        },
        ExpressionAttributeValues: {
          ":value":
            field === "rewardsPoints"
              ? { N: value.toString() }
              : field === "localPickup" || field === "requestedSeeds"
              ? { BOOL: value }
              : field === "isTester"
              ? { S: value.toString() }
              : { S: value.toString() },
        },
      };

      const command = new UpdateItemCommand(params);
      await client.send(command);
      console.log("User field updated successfully");
    } catch (err) {
      console.error("Error updating user field:", err);
    }
  };

  const parseUsers = (items) => {
    return items.map((user) => ({
      id: user.id?.S || "",
      email: user.email?.S || "",
      firstName: user.firstName?.S || "",
      lastName: user.lastName?.S || "",
      instagram_username: user.instagram_username?.S || "",
      createDate: user.createDate?.S || "",
      modifyDate: user.modifyDate?.S || "",
      rewardsPoints: parseInt(user.rewardsPoints?.N) || 0,
      orderCount: parseInt(user.orderCount?.N) || 0,
      testerCount: parseInt(user.testerCount?.N) || 0,
      isTester: user.isTester?.S === "true",
      requestedSeeds: user.requestedSeeds?.BOOL || false,
      localPickup: user.localPickup?.BOOL || false,
      tshirtSize: user.tshirtSize?.S || "",
      profilePic: user.profilePic?.S || "",
    }));
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const sortedUsers = React.useMemo(() => {
    if (!sortConfig.key) return filteredUsers;

    return [...filteredUsers].sort((a, b) => {
      if (sortConfig.key === "createDate" || sortConfig.key === "modifyDate") {
        const dateA = new Date(a[sortConfig.key]);
        const dateB = new Date(b[sortConfig.key]);
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      }

      if (typeof a[sortConfig.key] === "number") {
        return sortConfig.direction === "asc"
          ? a[sortConfig.key] - b[sortConfig.key]
          : b[sortConfig.key] - a[sortConfig.key];
      }

      const valueA = (a[sortConfig.key] || "").toString().toLowerCase();
      const valueB = (b[sortConfig.key] || "").toString().toLowerCase();

      if (valueA < valueB) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [filteredUsers, sortConfig]);

  const handleColumnToggle = (columnId) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  const handleToggle = (email, event, field) => {
    const newValue = event.target.checked;

    // Update local state first
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.email === email ? { ...user, [field]: newValue } : user
      )
    );

    // Track changes for saving - merge with any existing changes for this user
    setEditedUsers((prev) => ({
      ...prev,
      [email]: {
        ...prev[email],
        [field]: newValue,
      },
    }));
  };

  const handlePointsChange = (email, event) => {
    const newValue = parseInt(event.target.value, 10);
    setEditedUsers((prev) => ({
      ...prev,
      [email]: { ...prev[email], rewardsPoints: newValue },
    }));
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      const updates = Object.entries(editedUsers).flatMap(([email, fields]) =>
        Object.entries(fields).map(([field, value]) => ({
          email,
          field,
          value,
        }))
      );

      await Promise.all(
        updates.map((update) =>
          updateUserField(update.email, update.field, update.value)
        )
      );

      setShowCheckmark(true);
      setTimeout(() => setShowCheckmark(false), 2000);
      setEditedUsers({});
      await fetchUserData();
    } catch (error) {
      console.error("Error saving changes:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedUsers = filteredUsers.map((user) => user.id);
      setSelectedUsers(newSelectedUsers);
      setSelectAll(true);
    } else {
      setSelectedUsers([]);
      setSelectAll(false);
    }
  };

  const paginatedUsers = sortedUsers.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const isSelected = (id) => selectedUsers.indexOf(id) !== -1;

  useEffect(() => {
    if (isXSmallScreen) {
      // Hide many columns for very small screens
      setVisibleColumns({
        firstName: false,
        lastName: false,
        email: false,
        instagram: false,
        createDate: false,
        modifyDate: false,
        rewardsPoints: false,
        orderCount: false,
        testerCount: false,
        tester: false,
        requestedSeeds: false,
        localPickup: false,
        tshirtSize: false,
        profilePic: false,
      });
    } else if (isSmallScreen) {
      // Show only the most critical columns
      setVisibleColumns({
        firstName: true,
        lastName: true,
        email: true,
        instagram: false,
        createDate: false,
        modifyDate: false,
        rewardsPoints: true,
        orderCount: false,
        testerCount: false,
        tester: false,
        requestedSeeds: false,
        localPickup: false,
        tshirtSize: false,
        profilePic: false,
      });
    } else if (isMediumScreen) {
      // Show more columns for medium screens
      setVisibleColumns({
        firstName: true,
        lastName: true,
        email: true,
        instagram: true,
        createDate: true,
        modifyDate: false,
        rewardsPoints: true,
        orderCount: true,
        testerCount: false,
        tester: false,
        requestedSeeds: false,
        localPickup: false,
        tshirtSize: false,
        profilePic: false,
      });
    } else {
      // For large screens and above, display most columns
      setVisibleColumns({
        firstName: true,
        lastName: true,
        email: true,
        instagram: true,
        createDate: true,
        modifyDate: true,
        rewardsPoints: true,
        orderCount: true,
        testerCount: true,
        tester: true,
        requestedSeeds: true,
        localPickup: true,
        tshirtSize: true,
        profilePic: false,
      });
    }
  }, [
    isXSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isXLargeScreen,
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "auto",
        boxSizing: "border-box",
        margin: "auto",
        p: 3,
        minWidth: 0, // Ensure the box can shrink
      }}
    >
      <Typography
        variant={isLargeScreen ? "h3" : isSmallScreen ? "h5" : "h4"}
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: 4,
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
        }}
      >
        <UserIcon sx={{ mr: 2 }} /> User Management
      </Typography>

      <Paper
        elevation={8}
        sx={{
          p: 3,
          borderRadius: 4,
          backgroundColor: "background.paper",
          backgroundImage:
            "linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <StyledSearchBar>
            <Search sx={{ mr: 2 }} />
            <TextField
              fullWidth
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="standard"
            />
            <Tooltip title="Refresh">
              <IconButton
                onClick={() => {
                  setUsers([]);
                  setFilteredUsers([]);
                  fetchUserData();
                }}
                disabled={loading}
                sx={{
                  ml: 1,
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": { transform: "rotate(360deg)" },
                }}
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          </StyledSearchBar>

          <Tooltip title="Column Settings">
            <IconButton onClick={() => setOpenSettings(true)}>
              <Settings />
            </IconButton>
          </Tooltip>
        </Box>

        <StyledTableContainer>
          <Table size={isSmallScreen ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                <ResponsiveTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedUsers.length > 0 &&
                      selectedUsers.length < filteredUsers.length
                    }
                    checked={selectAll}
                    onChange={handleSelectAllClick}
                    icon={<Stars />}
                    checkedIcon={<Stars />}
                  />
                </ResponsiveTableCell>

                {columns.map(
                  (column) =>
                    visibleColumns[column.id] && (
                      <ResponsiveTableCell
                        key={column.id}
                        onClick={() => handleSort(column.id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {column.icon}
                          <ResponsiveText sx={{ ml: 1 }}>
                            {column.label}
                          </ResponsiveText>
                          {sortConfig.key === column.id &&
                            (sortConfig.direction === "asc" ? (
                              <ArrowUpward />
                            ) : (
                              <ArrowDownward />
                            ))}
                        </Box>
                      </ResponsiveTableCell>
                    )
                )}
                <ResponsiveTableCell>Actions</ResponsiveTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <AnimatePresence>
                {paginatedUsers.map((user, index) => (
                  <motion.tr
                    key={user.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.4, delay: index * 0.1 }}
                  >
                    <ResponsiveTableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected(user.id)}
                        onChange={(event) => {
                          const selectedIndex = selectedUsers.indexOf(user.id);
                          let newSelected = [];

                          if (selectedIndex === -1) {
                            newSelected = newSelected.concat(
                              selectedUsers,
                              user.id
                            );
                          } else if (selectedIndex === 0) {
                            newSelected = newSelected.concat(
                              selectedUsers.slice(1)
                            );
                          } else if (
                            selectedIndex ===
                            selectedUsers.length - 1
                          ) {
                            newSelected = newSelected.concat(
                              selectedUsers.slice(0, -1)
                            );
                          } else if (selectedIndex > 0) {
                            newSelected = newSelected.concat(
                              selectedUsers.slice(0, selectedIndex),
                              selectedUsers.slice(selectedIndex + 1)
                            );
                          }

                          setSelectedUsers(newSelected);
                        }}
                        icon={<Stars />}
                        checkedIcon={<Stars />}
                      />
                    </ResponsiveTableCell>

                    {visibleColumns.firstName && (
                      <ResponsiveTableCell>
                        {user.firstName}
                      </ResponsiveTableCell>
                    )}
                    {visibleColumns.lastName && (
                      <ResponsiveTableCell>{user.lastName}</ResponsiveTableCell>
                    )}
                    {visibleColumns.email && (
                      <ResponsiveTableCell>{user.email}</ResponsiveTableCell>
                    )}
                    {visibleColumns.instagram && (
                      <ResponsiveTableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Instagram sx={{ mr: 1, color: "#E1306C" }} />
                          {user.instagram_username}
                        </Box>
                      </ResponsiveTableCell>
                    )}
                    {visibleColumns.createDate && (
                      <ResponsiveTableCell>
                        {new Date(user.createDate).toLocaleString()}
                      </ResponsiveTableCell>
                    )}
                    {visibleColumns.rewardsPoints && (
                      <ResponsiveTableCell>
                        <TextField
                          type="number"
                          value={user.rewardsPoints}
                          onChange={(e) => handlePointsChange(user.email, e)}
                          size="small"
                          sx={{ width: "80px" }}
                          inputProps={{ min: 0 }}
                        />
                      </ResponsiveTableCell>
                    )}
                    {visibleColumns.orderCount && (
                      <ResponsiveTableCell>
                        {user.orderCount}
                      </ResponsiveTableCell>
                    )}
                    {visibleColumns.testerCount && (
                      <ResponsiveTableCell>
                        {user.testerCount}
                      </ResponsiveTableCell>
                    )}
                    {visibleColumns.tester && (
                      <ResponsiveTableCell>
                        <Switch
                          checked={user.isTester}
                          onChange={(e) =>
                            handleToggle(user.email, e, "isTester")
                          }
                          color="primary"
                          size={isSmallScreen ? "small" : "medium"}
                          sx={{
                            "& .MuiSwitch-thumb": {
                              backgroundColor: user.isTester
                                ? "#4caf50"
                                : "#f44336",
                            },
                          }}
                        />
                      </ResponsiveTableCell>
                    )}
                    {/* Requested Seeds Switch */}
                    {visibleColumns.requestedSeeds && (
                      <ResponsiveTableCell>
                        <Switch
                          checked={user.requestedSeeds}
                          onChange={(e) =>
                            handleToggle(user.email, e, "requestedSeeds")
                          }
                          color="primary"
                          size={isSmallScreen ? "small" : "medium"}
                          sx={{
                            "& .MuiSwitch-thumb": {
                              backgroundColor: user.requestedSeeds
                                ? "#4caf50"
                                : "#f44336",
                            },
                          }}
                        />
                      </ResponsiveTableCell>
                    )}

                    {/* Local Pickup Switch */}
                    {visibleColumns.localPickup && (
                      <ResponsiveTableCell>
                        <Switch
                          checked={user.localPickup}
                          onChange={(e) =>
                            handleToggle(user.email, e, "localPickup")
                          }
                          color="primary"
                          size={isSmallScreen ? "small" : "medium"}
                          sx={{
                            "& .MuiSwitch-thumb": {
                              backgroundColor: user.localPickup
                                ? "#4caf50"
                                : "#f44336",
                            },
                          }}
                        />
                      </ResponsiveTableCell>
                    )}
                    {visibleColumns.tshirtSize && (
                      <ResponsiveTableCell>
                        <TextField
                          value={user.tshirtSize}
                          size="small"
                          sx={{ width: "60px" }}
                        />
                      </ResponsiveTableCell>
                    )}
                    <ResponsiveTableCell>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Tooltip
                          title="View Orders"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <IconButton
                            component={Link}
                            to={`/orders/user/${user.id}`}
                            size="small"
                            color="primary"
                            sx={{
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": { transform: "scale(1.2)" },
                            }}
                          >
                            <ShoppingCart sx={{ }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="View Details"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <IconButton
                            component={Link}
                            to={`/users/${user.id}`}
                            size="small"
                            color="white"
                            sx={{
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": { transform: "scale(1.2)" },
                            }}
                          >
                            <ViewList />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </ResponsiveTableCell>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </TableBody>
          </Table>
        </StyledTableContainer>

        <TablePagination
          component="div"
          count={sortedUsers.length}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />

        <SettingsModal
          open={openSettings}
          onClose={() => setOpenSettings(false)}
        >
          <ModalContent>
            <Typography variant="h6" gutterBottom>
              Column Settings
            </Typography>
            <List>
              {columns.map((column) => (
                <ListItem key={column.id}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {column.icon}
                    <ListItemText primary={column.label} sx={{ ml: 2 }} />
                  </Box>
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      checked={visibleColumns[column.id]}
                      onChange={() => handleColumnToggle(column.id)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </ModalContent>
        </SettingsModal>

        {selectedUsers.length > 0 && (
          <Paper
            elevation={3}
            sx={{
              mt: 2,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{selectedUsers.length} users selected</Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <TextField
                type="number"
                label="Reward Points"
                value={rewardPoints}
                onChange={(e) => setRewardPoints(parseInt(e.target.value, 10))}
                size="small"
                inputProps={{ min: 0 }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => {
                  const newUsers = users.map((user) => {
                    if (selectedUsers.includes(user.id)) {
                      return {
                        ...user,
                        rewardsPoints: user.rewardsPoints + rewardPoints,
                      };
                    }
                    return user;
                  });
                  setUsers(newUsers);
                  setSelectedUsers([]);
                  setRewardPoints(0);
                }}
              >
                Add Points
              </Button>
            </Box>
          </Paper>
        )}

        {Object.keys(editedUsers).length > 0 && (
          <Box
            sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            {showCheckmark && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkmark size="small" />
                <Typography sx={{ ml: 1, color: "success.main" }}>
                  Changes saved successfully!
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              color="primary"
              startIcon={loading ? <CircularProgress size={20} /> : <Check />}
              onClick={handleSaveChanges}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Changes"}
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default UserTable;
